import React, { useState } from "react";
import InitState from "../components/testZaTip/init-state";
import ResultsTip from "../components/testZaTip/results";

import "./style.css";

function TestZaTip() {
  const [flag, setFlag] = useState("init");
  const [resultsArray, setResultsArray] = useState([]);

  if (flag === "init") {
    return (
      <InitState
        setFlag={setFlag}
        setResultsArray={setResultsArray}
      ></InitState>
    );
  } else if (flag === "results") {
    return <ResultsTip resultsArray={resultsArray}></ResultsTip>;
  }
}

export default TestZaTip;
