import React from "react";
import TableHead from "./tableComponents/tableHead";
import RadioButtons from "./tableComponents/radioButtons";

function TableTip(props) {
  return (
    <div>
      <table className="blueTable">
        <TableHead />
        <tbody>
          <RadioButtons
            title={"ICR"}
            name="1"
            text={
              "1. Сакам да пребарувам и анализирам информации за комплексни и нови работи за мене, дури и ако тие не се поврзани со постигнување конкретен резултат во моментот."
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"SR"}
            name="2"
            text={
              "2. Во случај на проблем, претпочитам заедно, преку дијалог со другите, да постигнеме заедничко решение, наместо некој да наметнува решение за сите."
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"SC"}
            name="3"
            text={
              "3. Секогаш сум подготвен да се откажам од работата за да им помогнам на луѓето околу мене или да се вклучам во разни волонтерски иницијативи."
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"IR"}
            name="4"
            text={
              "4. Сакам предмети како математика, физика, хемија, астрономија, биологија."
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"C"}
            name="5"
            text={
              "5. Во моите тетратки сé е прегледно и убаво напишано и наредено."
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"S"}
            name="6"
            text={
              "6. Претпочитам директен пристап наместо да ја заобиколувам темата."
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"EA"}
            name="7"
            text={"7. Се сметам за оригинална и различна личност од другите."}
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"R"}
            name="8"
            text={
              "8. Претпочитам да имам јасни упатства и правила за да направам нешто на најдобар начин."
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"A"}
            name="9"
            text={
              "9. Имам извонреден талент и вештини во областа на уметноста (музика, театар, сликарство, танц, пишување, итн.)"
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"C"}
            name="10"
            text={
              "10. Сакам мојата соба и предмети да се уредни и во секој момент да знам што е и каде е."
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"R"}
            name="11"
            text={
              "11. Сакам да бидам физички активен/а и да правам нешто на отворено."
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"R"}
            name="12"
            text={
              "12. Претпочитам ситуации што се предвидливи, структурирани и со прелиминарни правила што треба да се следат."
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"EC"}
            name="13"
            text={
              "13. Секогаш знам дека во тешка ситуација или проблем ќе се снајдам и ќе најдам решение."
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"SE"}
            name="14"
            text={
              "14. Јас сум секогаш поттикнувач на организирање и правење нешто со пријателите."
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"SR"}
            name="15"
            text={
              "15. Секогаш ги одржувам своите ветувања кон другите, дури и ако понекогаш ми создава непријатности."
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"A"}
            name="16"
            text={
              "16. Во дискусиите и дебатите повеќе се водам од моите емоции и интуиција отколку од логика."
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"A"}
            name="17"
            text={
              "17. Уживам да правам разни уметнички предмети или да учествувам во театарски групи, да сликам, да се претставувам пред публиката и сл."
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"RI"}
            name="18"
            text={
              "18. Ми претставува задоволство да работам со алатки, апарати, машини, технички средства."
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"CA"}
            name="19"
            text={
              "19. Имам многу јасна и правилна проценка на нештата и кога сум убеден во нешто, важно ми е да ги убедам другите дека сум во право, бидејќи обично е така."
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"E"}
            name="20"
            text={"20. За мене е важно да бидам најдобар."}
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"R"}
            name="21"
            text={
              "21. Кога правам нешто, сакам веднаш да го видам резултатот од моите напори."
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"ICA"}
            name="22"
            text={
              "22. Претпочитам да работам сам на проект, наместо да работам во тим или да се потпирам на други луѓе."
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"I"}
            name="23"
            text={
              "23. Имам тенденција да барам подобар начин да направам нешто, долго по завршувањето на задачата."
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
          <RadioButtons
            title={"SC"}
            name="24"
            text={
              "24. Јас секогаш знам како да му објаснам нешто на пријател за да разбере."
            }
            handleRadioInput={props.handleRadioInput}
            inputChange={props.setCount}
          />
        </tbody>
      </table>
    </div>
  );
}

export default TableTip;
