import React from "react";
import RadioButtons from "./radioButtons";
import TableHead from "./tableHead";

function LugjeTable(props) {
  return (
    <div>
      <p>
        {" "}
        <em> 6. Снаоѓање со луѓе </em>{" "}
      </p>
      <table className="blueTable">
        <TableHead />
        <tbody>
          <RadioButtons
            currentCount={props.count}
            inputChange={props.setCount}
            name={"row1"}
            text={
              "Твојот пријател/пријателка ги изгубила вашите карти  за концертот кој го очекуваш веќе неколку месеци. Својата лутина нема да ја покажеш пред него/неа. Ова за тебе би било:"
            }
          />
          <RadioButtons
            currentCount={props.count}
            inputChange={props.setCount}
            name={"row2"}
            text={
              "Колегата со кој работиш ти е видно налутен. Одма бараш начин да ја разјасниш ситуацијата. Ова за тебе би било:"
            }
          />
          <RadioButtons
            currentCount={props.count}
            inputChange={props.setCount}
            name={"row3"}
            text={
              "Другаркати ти се довери за еден свој голем проблем. Треба да ја сослушаш и да најдеш утешни зборови за неа. Ова за тебе би било:"
            }
          />
          <RadioButtons
            currentCount={props.count}
            inputChange={props.setCount}
            name={"row4"}
            text={
              "Кога гледаш филм дури и кога глумците не зборуваат можеш да ги процениш нивните чувства. Ова за тебе би било:"
            }
          />
        </tbody>
      </table>
    </div>
  );
}

export default LugjeTable;
