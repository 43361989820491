import React, { useState } from "react";
import Title from "../title";
import Description from "./description";
import Table from "./table";
import Profesii from "../../constants";

function InitState(props) {
  const [zborovi, setZborovi] = useState(0);
  const [broevi, setBroevi] = useState(0);
  const [uredi, setUredi] = useState(0);
  const [prostor, setProstor] = useState(0);
  const [prsti, setPrsti] = useState(0);
  const [lugje, setLugje] = useState(0);
  const [required, setRequired] = useState(-1);

  let proseci = {
    zborovi: zborovi / 4,
    broevi: broevi / 4,
    uredi: uredi / 4,
    prostor: prostor / 4,
    prsti: prsti / 4,
    lugje: lugje / 4,
  };

  return (
    <section className="container">
      <Title title={"ТЕСТ ЗА СПОСОБНОСТИ"}></Title>
      <Description />

      <div className="desc-wrapper">
        <h3> Внесете го Вашето име: </h3>
        <input
          type="text"
          name="ime"
          onChange={props.setIme}
          placeholder="Име и презиме..."
        />
      </div>
      <Table
        zborovi={zborovi}
        setZborovi={setZborovi}
        broevi={broevi}
        setBroevi={setBroevi}
        uredi={uredi}
        setUredi={setUredi}
        prostor={prostor}
        setProstor={setProstor}
        prsti={prsti}
        setPrsti={setPrsti}
        lugje={lugje}
        setLugje={setLugje}
      />
      <button className="submit" onClick={genResults}>
        Испрати
      </button>
      {required === 2 && (
        <h3 style={{ color: "red" }}>*All fields are required!</h3>
      )}
    </section>
  );

  function genResults() {
    if (
      props.ime.target !== undefined &&
      props.ime.target.value !== "" &&
      zborovi !== 0 &&
      broevi !== 0 &&
      uredi !== 0 &&
      prostor !== 0 &&
      prsti !== 0 &&
      lugje !== 0
    ) {
      let sortable = [];

      for (var p in proseci) {
        sortable.push([p, proseci[p]]);
      }

      sortable
        .sort(function (a, b) {
          return a[1] - b[1];
        })
        .reverse()
        .slice(0, 3)
        // eslint-disable-next-line array-callback-return
        .some((value) => {
          Profesii[value[0].toString()].forEach((e) => {
            props.setResults((results) => {
              if (results.includes(e)) {
                let index = results.indexOf(e);
                let items = results;
                let item = items[index];
                item += "(2)";
                items[index] = item;
                return items;
              } else {
                return [...results, e];
              }
            });
          });
        });
      props.setFlag("results-1");
      setRequired(1);
    } else setRequired(2);
  }
}

export default InitState;
