import React from "react";
import TableHead from "./tableComponents/tableHead";
import RadioButtons from "./tableComponents/radioButtons";

function TableCareer(props) {
  return (
    <table className="blueTable">
      <TableHead />
      <tbody>
        <RadioButtons
          title={"R"}
          name="1"
          text={
            "1. Важно ми е повеќето луѓе да ми ја уважуваат професијата и работното место."
          }
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"L"}
          name="2"
          text={"2. Сакам да ме доживуваат како лидер на моето работно место."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"C"}
          name="3"
          text={"3. Во сé што правам, барам нови можности и предизвици."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"M"}
          name="4"
          text={
            "4. За мене е важно да добијам највисока можна плата за мојата позиција."
          }
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"T"}
          name="5"
          text={
            "5. Во моите тетратки сé е прегледно и убаво напишано и наредено."
          }
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"G"}
          name="6"
          text={"6. Ми се допаѓа да работам со други луѓе."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"A"}
          name="7"
          text={"7. Сакам да ризикувам."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"H"}
          name="8"
          text={
            "8. Кога извршувам задача, важно ми е да ја направам на најдобар можен начин."
          }
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"V"}
          name="9"
          text={
            "9. Ми се допаѓа мојата работа да вклучува широк спектар на задачи."
          }
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"I"}
          name="10"
          text={
            "10. Сакам да планирам и извршувам работни задачи по сопствена дискреција."
          }
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"L"}
          name="11"
          text={"11. Сакам да бидам одговорен, без разлика што правам."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"R"}
          name="12"
          text={"12. Важно ми е каде работам."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"T"}
          name="13"
          text={"13. Тимската работа ми ја олеснува работата."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"A"}
          name="14"
          text={"14. Без разлика каде работам, сакам да учам нови работи."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"H"}
          name="15"
          text={"15. Се чувствувам добро кога можам да помогнам некому."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"G"}
          name="16"
          text={"16. Ми се допаѓа работа што вклучува непознато."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"C"}
          name="17"
          text={"17. Лесно ми станува досадно."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"V"}
          name="18"
          text={"18. Сакам да работам и да запознавам различни луѓе."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"M"}
          name="19"
          text={"19. Сакам да ги имам најновите и најмодерните технологии."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"R"}
          name="20"
          text={"20. Позицијата што ја заземам е многу важна за мене."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"I"}
          name="21"
          text={"21. Не примам лесно наредби од претпоставените."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"C"}
          name="22"
          text={
            "22. Ми се допаѓаат работни задачи кои бараат примена на иновативен пристап."
          }
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"T"}
          name="23"
          text={"23. Уживам да работам со луѓе кои мислат како мене."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"A"}
          name="24"
          text={
            "24. Останувам на иста работа само додека гледам предизвик во неа."
          }
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"L"}
          name="25"
          text={"25. Сакам да ги контролирам процесите и луѓето на работа."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"M"}
          name="26"
          text={"26. Материјалните нешта ми се многу важни."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"R"}
          name="27"
          text={"27. Важно ми е другите да ме почитуваат."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"G"}
          name="28"
          text={
            "28. Ми се допаѓа да се зафатам со задача со која некој друг не може да се справи."
          }
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"H"}
          name="29"
          text={"29. Убаво ми е да им помагам на новите вработени."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"V"}
          name="30"
          text={"30. Сакам да си го менувам работното место. "}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"I"}
          name="31"
          text={
            "31. Сакам да имам можност да ги структурирам задачите како што сакам јас."
          }
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"C"}
          name="32"
          text={"32. Ми се допаѓа да пробувам нови и креативни идеи."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"M"}
          name="33"
          text={"33. Сакам да поседувам луксузни и квалитетни работи."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"L"}
          name="34"
          text={
            "34. Сакам да бидам одговорен/а за планирање и организирање активности."
          }
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"H"}
          name="35"
          text={
            "35. Ако некој колега направил грешка на работа, ќе му дадам повратни информации како да го поправи тоа."
          }
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"A"}
          name="36"
          text={"36. Барам можности да придонесам за моето работно место."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"T"}
          name="37"
          text={
            "37. Обично се справувам подобро со која било работа ако има некој да ми помогне."
          }
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"I"}
          name="38"
          text={"38. Претпочитам да работам самостојно и без инструкции."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"G"}
          name="39"
          text={"39. Сакам работни места каде има предизвици."}
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
        <RadioButtons
          title={"V"}
          name="40"
          text={
            "40. Во мојот професионален живот досега сум се занимавал/а со многу различни работи."
          }
          handleRadioInput={props.handleRadioInput}
          inputChange={props.setCount}
        />
      </tbody>
    </table>
  );
}

export default TableCareer;
