import React from "react";
import Title from "../title";
import ResultsSummary from "./results-summary";

function ResultsTip(props) {
  return (
    <section className="res-container">
      <Title title={"Резултати - Тест за тип на личност:"}></Title>
      <ResultsSummary resultsArray={props.resultsArray}></ResultsSummary>
    </section>
  );
}

export default ResultsTip;
