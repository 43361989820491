/* eslint-disable array-callback-return */
import React, { useEffect } from "react";
import Realistic from "./tipovi-components/realistic";
import Investigative from "./tipovi-components/investigative";
import Artistic from "./tipovi-components/artistic";
import Social from "./tipovi-components/social";
import Entrepreneur from "./tipovi-components/entrepreneur";
import Conventional from "./tipovi-components/conventional";

function ResultsSummary(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="desc-wrapper">
      <h3>Успешно го пополнивте прашалникот.</h3> <br /> <br />
      <h3>
        Врз основа на одговорите што ги дадовте, вие сте тип на личност која се
        карактеризира како: <br />
      </h3>
      <p style={{ color: "blue" }}>
        <strong>{props.resultsArray.join(", ")}</strong>
      </p>
      <br />
      <p>
        Овој тест се базира на една од најпопуларните теории за професионален
        избор, развиена од Џон Холанд во 1960 -тите години од 20-от век.
      </p>
      <p>
        Врз основа на нивните карактеристики и преференции, Холанд идентификува
        <strong> шест главни типови на личност </strong> (познати по кратенката
        RIASEC): реалисти, истражувачи, уметници, социјални, претприемачи и
        конвенционални. Тие исто така одговараат на различни видови професии, во
        кои секој специфичен тип се чувствува како дома и најдобро може да ги
        изрази своите способности.
      </p>
      <p>
        Повеќето луѓе се комбинација од еден водечки и два подтипа, од кои
        секоја комбинација дефинира широк спектар на можни професии.{" "}
      </p>
      <br />
      {props.resultsArray.map((element) => {
        if (element === "реалистичен") {
          return <Realistic></Realistic>;
        }
        if (element === "истражувачки") {
          return <Investigative></Investigative>;
        }
        if (element === "уметнички") {
          return <Artistic></Artistic>;
        }
        if (element === "социјален") {
          return <Social></Social>;
        }
        if (element === "предприемачески") {
          return <Entrepreneur></Entrepreneur>;
        }
        if (element === "традиционален") {
          return <Conventional></Conventional>;
        }
      })}
      Овие резултати се појдовна точка за насока и размислување при изборот на
      вистинската насока во кариерата. Доколку се двоумите, ве советуваме да
      побарате помош од советник за кариера кој ќе ви помогне да донесете
      одлука.
    </div>
  );
}

export default ResultsSummary;
