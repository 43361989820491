import React from "react";

function Results1(props) {
  return (
    <div className="results">
      <h3>
        Според Вашите одговори ги добивте следните занимања. <br /> Изберете ги
        занимањата кои Ве интересираат:
      </h3>
      <table>
        <tbody></tbody>
        <tbody>{showTable()}</tbody>
      </table>
      <button className="submit" onClick={getResults}>
        Испрати
      </button>
    </div>
  );

  function showTable() {
    const tableRows = [];
    let resultsArr = props.results;

    for (let i = 0; i < resultsArr.length; i = i + 1) {
      if (resultsArr[i].includes('(2)')){
        resultsArr[i] = resultsArr[i].substring(0, resultsArr[i].length - 3);
      }
    }

    for (let i = 0; i < resultsArr.length; i = i + 4) {
      tableRows.push(
        <tr
          key={
            resultsArr[i] +
            "-" +
            resultsArr[i + 1] +
            "-" +
            resultsArr[i + 2] +
            "-" +
            resultsArr[i + 3]
          }
        >
          <td>{resultsArr[i]}</td>
          <td>
            <input
              type="checkbox"
              value={resultsArr[i]}
              onChange={getClicked}
            />
          </td>

          <td>{resultsArr[i + 1]}</td>
          <td>
            <input
              type="checkbox"
              value={resultsArr[i + 1]}
              onChange={getClicked}
            />
          </td>
          <td>{resultsArr[i + 2]}</td>
          <td>
            {resultsArr[i + 2] && (
              <input
                type="checkbox"
                value={resultsArr[i + 2]}
                onChange={getClicked}
              />
            )}
          </td>

          <td>{resultsArr[i + 3]}</td>
          <td>
            {resultsArr[i + 3] && (
              <input
                type="checkbox"
                value={resultsArr[i + 3]}
                onChange={getClicked}
              />
            )}
          </td>
        </tr>
      );
    }
    return tableRows;
  }

  function getResults() {
    if (props.clicked.length === 0) {
      console.log("Select at least one item"); // to-do
    } else props.setFlag("results-2");
  }

  function getClicked(e) {
    props.setClicked((clicked) => {
      let items = clicked;
      let item = e.target.value;
      if (items.includes(item)) {
        let index = clicked.indexOf(item);
        if (index > -1) {
          items.splice(index, 1);
          return items;
        }
      } else if (item.includes("(2)")) {
        items.unshift(item);
        return items;
      } else return [...clicked, item];
    });
  }
}

export default Results1;
