import React from "react";
import RadioButtons from "./radioButtons";
import TableHead from "./tableHead";

function ProstorTable(props) {
  return (
    <div>
      <p>
        {" "}
        <em> 4. Сфаќање на односите во рамнина и простор </em>{" "}
      </p>
      <table className="blueTable">
        <TableHead />
        <tbody>
          <RadioButtons
            name={"row1"}
            text={
              "На излет си со друштво. Треба да ја следиш картата на тврдината за да стигнете заедно до кулата. Ова за тебе би било:"
            }
            currentCount={props.count}
            inputChange={props.setCount}
          />
          <RadioButtons
            name={"row2"}
            text={
              "Потребно  е со слободна рака да го нацрташ распоредот на просториите во твојата куќа. Не е важно дали цртежот убаво изгледа, туку дали распоредот е соодветен. Ова за тебе би било:"
            }
            currentCount={props.count}
            inputChange={props.setCount}
          />
          <RadioButtons
            name={"row3"}
            text={
              "Во продавница бираш полица за книги. Треба да процениш дали полицата може да се застане во слободниот простор во твојата соба. Ова за тебе би било:"
            }
            currentCount={props.count}
            inputChange={props.setCount}
          />
          <RadioButtons
            name={"row4"}
            text={
              "Купивте расклопена маса. Приложена е скица од масата. Врз основа на скицата треба да ја споиш масата. Ова за тебе би било:"
            }
            currentCount={props.count}
            inputChange={props.setCount}
          />
        </tbody>
      </table>
    </div>
  );
}

export default ProstorTable;
