import React from "react";
import photo from "../../../assets/investigative.jpg";

function Investigative() {
  return (
    <div>
      <h1>ИСТРАЖУВАЧКИ ТИП НА ЛИЧНОСТ</h1>
      <img src={photo} alt="investigative" />
      <h3>
        <em>Квалитети:</em>
      </h3>
      <p>
        Типот на личност на истражувач е: аналитичен, љубопитен, критичен,
        рационален, размислува, независен, методичен, претпазлив, прецизен.
      </p>
      <h3>
        <em>Поволна работна средина:</em>
      </h3>
      <ul>
        <li>
          поврзани со систематско истражување, проучување, анализа, евалуација,
          решавање проблеми, потрага по причинско-последични врски, обрасци и
          склопување на модели за предвидување, итн.
        </li>
      </ul>
      <h3>
        <em>
          Професии и занимања, соодветни за личности, со доминантен истражувачки
          тип:
        </em>
      </h3>
      <p>
        Практично секое професионално поле има структури во кои се развиваат,
        тестираат, анализираат и истражуваат различни работи. Ако некое лице има
        водечки тип на истражување, важно е да се утврди која од сите
        професионални области е од најголем интерес за него / неа, како и кои се
        неговите други подтипови.
      </p>
      <ul>
        <li>
          Специјалисти за развој и тестирање на информатичка технологија (ИТ
          специјалисти), инженери - развивачи на нови технологии, бизнис
          анализа, анализа на податоци и составување математички модели;
        </li>
        <li>
          Научни соработници, техничари и истражувачи од областа на здравјето и
          менталното здравје - генетичар, медицински статистичар, лабораториски
          асистент, виролог, итн.;
        </li>
        <li>
          Истражувачки соработници во егзактните и природните науки -
          математичар, физичар, хемичар, биолог, молекуларен биолог, геолог,
          фармаколог, метеоролог, итн.;
        </li>
        <li>
          Научни соработници и истражувачи од областа на општествените науки -
          право, историја, психологија, економија, социологија, политички науки,
          културологија, лингвистика и др.
        </li>
      </ul>
    </div>
  );
}

export default Investigative;
