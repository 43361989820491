const Profesii = {
  zborovi: [
    "преведувачи",
    "писатели",
    "новинари",
    "водители",
    "соработници за односи со јавност",
    "психолози",
    "наставници",
    "професори",
    "воспитувачи",
    "економисти",
    "правници",
    "адвокати",
    "туристички водичи",
  ],
  broevi: [
    "математичари",
    "физичари",
    "хемичари",
    "информатичари",
    "електротехничари",
    "градежници",
    "геодети",
    "статистичари",
    "лаборанти",
    "келнери",
    "економисти",
    "финансиски техничари",
    "инженери на технички науки",
  ],
  uredi: [
    "машински инженери и техничари",
    "автомеханичари",
    "електротехничари",
    "возачи",
    "сообраќајни техничари",
  ],

  prostor: [
    "архитекти",
    "градежници",
    "геодети",
    "дизајнери",
    "машински контруктори",
    "забни техничари",
    "зидари",
    "столари",
    "бравари",
    "кројачи",
    "возачи",
  ],

  prsti: [
    "хирурзи",
    "стоматолози",
    "забни техничари",
    "прецизни механичари",
    "оптичари",
    "електроничари",
    "вајари",
    "златари",
    "фризери",
    "цвеќари",
    "слаткари",
    "кројачи",
    "келнери",
    "механичари",
    "масери",
    "физиотерапеути",
  ],
  lugje: [
    "воспитувачи",
    "наставници",
    "професори",
    "лекари",
    "медицински техничари",
    "болничари",
    "педагози",
    "психолози",
    "новонари",
    "кустоси",
    "туристички работници",
    "службеници на шалтер",
    "продавачи",
    "менаџери",
  ],
};

export default Profesii;
