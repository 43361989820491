import React, { useState } from "react";

import "./style.css";
import Results1 from "../components/testZaSposobnosti/results-1";
import Results2 from "../components/testZaSposobnosti/results-2";
import InitState from "../components/testZaSposobnosti/init-state";

function TestZaSposobnosti() {
  const [results, setResults] = useState([]);
  const [flag, setFlag] = useState("init");
  const [clicked, setClicked] = useState([]);
  const [ime, setIme] = useState({});

  if (flag === "init") {
    return (
      <InitState
        setResults={setResults}
        setFlag={setFlag}
        ime={ime}
        setIme={setIme}
      ></InitState>
    );
  } else if (flag === "results-1") {
    return (
      <Results1
        results={results}
        setFlag={setFlag}
        clicked={clicked}
        setClicked={setClicked}
      ></Results1>
    );
  } else if (flag === "results-2") {
    return <Results2 clicked={clicked} ime={ime.target.value}></Results2>;
  }
}

export default TestZaSposobnosti;
