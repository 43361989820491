import React from "react";

function TableHead() {
  return(
    <thead>
      <tr>
        <th>проблем</th>
        <th>многу <br /> тешко</th>
        <th>тешко</th>
        <th>и тешко <br /> и лесно</th>
        <th>лесно</th>
        <th>многу <br /> лесно</th>
      </tr>
    </thead>
  )
}

export default TableHead