import React from "react";
import photo from "../../../assets/conventional.jpg";

function Conventional() {
  return (
    <div>
      <h1>ТРАДИЦИОНЕН/КОНВЕНЦИОНАЛЕН ТИП НА ЛИЧНОСТ </h1>
      <img src={photo} alt="conventional" />
      <h3>
        <em>Квалитети:</em>
      </h3>
      <p>
        Традиционалниот тип на личност е: совесен, вреден, точен, упорен,
        внимателен, уреден, исполнителен, дисциплиниран, штедлив, често
        конзервативен.
      </p>
      <h3>
        <em>Поволна работна средина:</em>
      </h3>
      <ul>
        <li>
          активности кои бараат обработка, систематизација и распоред на
          податоци, бројки, информации и документи;
        </li>
        <li>
          структурирани работни процеси во кои постојат јасни и прецизни правила
          и методологија, следејќи ги инструкциите;
        </li>
        <li>задачи кои бараат одговорност и внимание на деталите;</li>
        <li>административни и организациски активности, работа со пари.</li>
      </ul>
      <h3>
        <em>
          Професии и занимања, соодветни за личности, со доминантен
          традиционален тип:
        </em>
      </h3>
      <ul>
        <li>
          Сметководител, финансиер, финансиски аналитичар, ревизор, актуар,
          даночен инспектор, специјалист за обработка на плаќања; касиер,
          банкарски службеник;
        </li>
        <li>
          Секретар / асистент / организатор, вработен во Одделот за човечки
          ресурси;
        </li>
        <li>
          Оператор на бази на податоци и обработка на информации; специјалист за
          архивирање информации, библиотекар, статистичар;
        </li>
        <li>
          Специјалист за безбедност на компјутерски системи; одржување на
          компјутерски системи;
        </li>
        <li>Инспектор за квалитетни или безбедни услови за работа;</li>
        <li>
          Вработени на производствена линија, менаџер за квалитет, инкасатор;
        </li>
        <li>Вработен на итна телефонска линија;</li>
        <li>Царински, здравствен инспектор итн.</li>
      </ul>
    </div>
  );
}

export default Conventional;
