import React from "react";
import ZboroviTable from "./tableComponents/zboroviTable";
import BroeviTable from "./tableComponents/broeviTable";
import UrediTable from "./tableComponents/urediTable";
import ProstorTable from "./tableComponents/prostorTable";
import PrstiTable from "./tableComponents/prstiTable";
import LugjeTable from "./tableComponents/lugjeTable";

function Table(props) {
  return (
    <div>
      <ZboroviTable count={props.zborovi} setCount={props.setZborovi} />
      <BroeviTable count={props.broevi} setCount={props.setBroevi} />
      <UrediTable count={props.uredi} setCount={props.setUredi} />
      <ProstorTable count={props.prostor} setCount={props.setProstor} />
      <PrstiTable count={props.prsti} setCount={props.setPrsti} />
      <LugjeTable count={props.lugje} setCount={props.setLugje} />
    </div>
  );
}

export default Table;
