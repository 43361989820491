import React from "react";

class RadioButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      row1: undefined,
      row2: undefined,
      row3: undefined,
      row4: undefined,
      current: "-1",
    };
    this.changeCurrent = this.changeCurrent.bind(this);
  }

  changeCurrent = (e) => {
    this.setState({
      current: e.target.value,
    });
  };

  render() {
    return (
      <tr>
        <td> {this.props.text} </td>
        <td>
          <label>
            Да
            <input
              onChange={this.props.handleRadioInput}
              onClick={this.changeCurrent}
              type="radio"
              value="1"
              name={this.props.name}
              title={this.props.title}
              checked={this.state.current === "1"}
            />
          </label>
        </td>
        <td>
          <label>
            Не
            <input
              onChange={this.props.handleRadioInput}
              onClick={this.changeCurrent}
              type="radio"
              value="0"
              name={this.props.name}
              title={this.props.title}
              checked={this.state.current === "0"}
            />
          </label>
        </td>
      </tr>
    );
  }
}

export default RadioButtons;
