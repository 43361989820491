import React from "react";

function Description() {
  return (
    <div className="desc-wrapper">
      <p>
        Овој тест има за цел да ви каже каков тип на личност сте и какви
        професии и работна средина би биле соодветни за вас. Прашалникот се
        состои од 24 изјави. Ве молиме наведете до кој степен секоја изјава важи
        за вас или би важела доколку се најдете во слична ситуација. Нема
        правилни или погрешни изјави, ниту поважни ниту помалку важни. Важно е
        да одговорите искрено, спонтано и брзо. <br /> <br />
        Прочитајте и забележете дали оваа изјава се однесува на вас:
      </p>
    </div>
  );
}

export default Description;
