import React from "react";
import photo from "../../../assets/realistic.jpg";

function Realistic() {
  return (
    <div>
      <h1>РЕАЛИСТИЧЕН ТИП НА ЛИЧНОСТ</h1>
      <img src={photo} alt="realistic" />
      <h3>
        <em>Квалитети:</em>
      </h3>
      <p>
        Реалистичниот тип на личност е: практичен, вешт, директен, фокусиран на
        материјални работи, упорен, отворен, трезвен, здрав, штедлив, издржлив,
        силен и атлетски.
      </p>
      <h3>
        <em>Поволна работна средина:</em>
      </h3>
      <ul>
        <li>
          Работна средина која има јасна и уредена работна структура, со висок
          степен на одговорност и потреба од прецизни и брзи реакции;
        </li>
        <li>Работа што има видлив резултат и практична вредност;</li>
        <li>Работа со техника, машини, алати и опрема;</li>
        <li>Работа на отворено;</li>
        <li>Работа со растенија и животни;</li>
        <li>
          Работа која бара физичка активност, сила, издржливост и добра
          координација
        </li>
      </ul>
      <h3>
        <em>
          Професии и занимања, соодветни за личности, со доминантен реалистичен
          тип:
        </em>
      </h3>
      <ul>
        <li>
          Инженери специјалности: архитект, цртач, специјалист за одржување на
          радио и телевизиска опрема, технички надзор, инженер за ветерни
          турбини / соларни инсталации;
        </li>
        <li>
          Примена на законски одредби, безбедност, безбедност на трудот: судија,
          истражувач, пожарникар, полицаец, менаџер за квалитет, итн.;
        </li>
        <li>
          Специјалности поврзани со механика и опрема: авијациски техничар,
          автомеханичар, техничар за климатизација, сервисер за апарати за
          домаќинство, техничар за медицинска опрема итн.;
        </li>
        <li>
          Транспорт, дистрибуција, логистика и шпедиција: пилот, возач, капетан,
          морнар, шпедитер, работник во складиште, вработен во аеродром,
          оператор на тешка опрема и возила, итн.;
        </li>
        <li>
          Градежни специјалитети и одржување: водоинсталатер, ѕидар, градежен
          работник, оператор за обработка на метали и машина за сечење метал,
          стругар, бравар, заварувач, итн.;
        </li>
        <li>
          Во земјоделството и природните ресурси: техничар за земјоделска
          механизација, одгледувач на добиток, ветеринар, работник во засолниште
          за кучиња, земјоделец, шумар, техничар во лаборатории за храна,
          градинар / проекти за имплементација и одржување зелени поврѓини,
          итн.;
        </li>
        <li>
          Во здравството - забен техничар, техничар за протетика, билкар, итн.;
        </li>
        <li>
          Занаети - електрична енергија / електроника: специјалист за
          канцелариска опрема, специјалист за електрична инсталација, техничар
          за лифтови, специјалист за инсталација на телекомуникациска опрема,
          итн.;
        </li>
        <li>
          Занаети и услуги - изработка на производи од метал, дрво, пластика,
          текстил: производител на мебел, лимар, кројач, чевлар, златар,
          стругар, тапацир, итн.;
        </li>
        <li>
          Подготовка на храна: пекар, сецкач, готвач за брза храна, бариста,
          работник во кујна, итн.;
        </li>
        <li>
          Спорт - спортист, тренер, фитнес инструктор, одржување на спортски
          објекти, итн.;
        </li>
        <li>
          Во производството - инженер, техничар, работник во производство,
          работник во складиште, вработен за одржување на системи во индустриски
          претпријатија итн.
        </li>
      </ul>
    </div>
  );
}

export default Realistic;
