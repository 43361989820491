import React from "react";
import photo from "../../../assets/enterpreneur.jpg";

function Entrepreneur() {
  return (
    <div>
      <h1>ПРЕДПРИЕМАЧЕСКИ ТИП НА ЛИЧНОСТ</h1>
      <img src={photo} alt="enterpreneur" />
      <h3>
        <em>Квалитети:</em>
      </h3>
      <p>
        Претприемачкиот тип на личност е: амбициозен, авантуристички,
        оптимистички, лидер, енергичен, доминантен, нетрпелив, самоуверен,
        друштвен, бара популарност и одобрување, прави комбинации, упорен во
        остварувањето на целите, размислува пошироко/за големата слика, склон
        кон преземање ризици.
      </p>
      <h3>
        <em>Поволна работна средина:</em>
      </h3>
      <ul>
        <li>
          Активности што овозможуваат влијание, убедување, лидерство, управување
          со луѓе, делегирање задачи, распределба на ресурси;
        </li>
        <li>Работа која вклучува предизвици, барање нестандардни решенија;</li>
        <li>Работа во неизвесна, динамично променлива средина;</li>
        <li>
          Иновативна работа, поврзана со можноста за постигнување на нови цели,
          недостижни за другите луѓе.
        </li>
      </ul>
      <h3>
        <em>
          Професии и занимања, соодветни за личности, со доминантен
          предприемачески тип:
        </em>
      </h3>
      <ul>
        <li>
          Претприемачи и сопственици на бизнис; менаџери на сектори за развој на
          нови производи и услуги; менаџери на новоформирани компании;
          Специјалист за развој на бизнис, проект менаџер; специјалист за разни
          обуки и развој на програми за практиканти; менаџер за ризици; брокер
          за недвижности;
        </li>
        <li>
          Брокер; инвеститор и специјалист за заеми; трговски претставник,
          специјалист за осигурување и проценка на штета; прибирање на средства
          и фондови во невладини организации или фондации
        </li>
        <li>Организатор на настани, конференции, форуми;</li>
        <li>
          Самостојни специјалисти - комерцијални агенти, адвокати, приватни
          детективи итн.;
        </li>
        <li>Претприемачи во земјоделството;</li>
        <li>Специјалисти во рекламни и маркетинг агенции;</li>
        <li>
          Специјалисти за животна средина кои се занимаваат со биолошки отпад и
          други материјали со висок ризик;
        </li>
        <li>Политичари, лобисти, итн.</li>
      </ul>
    </div>
  );
}

export default Entrepreneur;
