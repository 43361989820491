import React from "react";

function Description() {
  return (
    <div className="desc-wrapper">
      <p>
        Овој тест има за цел да ви помогне да ги одредите вредностите што ви се
        важни на работното место. Подолу се наведени 40 искази/тврдења.
        Прочитајте го секој од нив и забележете дали одговара на вашите желби
        или не.
      </p>
    </div>
  );
}

export default Description;
