import React from "react";

function TableHead() {
  return (
    <thead>
      <tr>
        <th>Тврдења</th>
        <th>Ми се допаѓа</th>
        <th>Не ми се допаѓа</th>
      </tr>
    </thead>
  );
}

export default TableHead;
