import "./App.css";
import { Route, Switch, Redirect } from "react-router-dom";
import testZaSposobnosti from "./pages/testZaSposobnosti";
import testZaTip from "./pages/testZaTip";
import TestZaKariera from "./pages/testZaKariera";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/test-za-sposobnosti" component={testZaSposobnosti} />
        <Route exact path="/test-tip-licnost" component={testZaTip} />
        <Route exact path="/test-kariera" component={TestZaKariera} />
        <Redirect to="/test-tip-licnost" />
      </Switch>
    </div>
  );
}

export default App;
