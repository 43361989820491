import React from "react";

class RadioButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      row1: undefined,
      row2: undefined,
      row3: undefined,
      row4: undefined,
      current: "-1",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.changeCurrent = this.changeCurrent.bind(this);
  }

  changeCurrent = (e) => {
    this.setState({
      current: e.target.value,
    });
  };

  handleInputChange = (event) => {
    if (this.props.name === "row1" && this.state.row1 !== undefined) {
      this.props.inputChange(
        Number.parseInt(event.target.value) +
          Number.parseInt(this.props.currentCount) -
          Number.parseInt(this.state.row1)
      );
    } else if (this.props.name === "row2" && this.state.row1 !== undefined) {
      this.props.inputChange(
        Number.parseInt(event.target.value) +
          Number.parseInt(this.props.currentCount) -
          Number.parseInt(this.state.row2)
      );
    } else if (this.props.name === "row3" && this.state.row1 !== undefined) {
      this.props.inputChange(
        Number.parseInt(event.target.value) +
          Number.parseInt(this.props.currentCount) -
          Number.parseInt(this.state.row3)
      );
    } else if (this.props.name === "row4" && this.state.row1 !== undefined) {
      this.props.inputChange(
        Number.parseInt(event.target.value) +
          Number.parseInt(this.props.currentCount) -
          Number.parseInt(this.state.row4)
      );
    }

    if (
      this.state.row1 === undefined ||
      this.state.row2 === undefined ||
      this.state.row3 === undefined ||
      this.state.row4 === undefined
    ) {
      this.props.inputChange(
        Number.parseInt(event.target.value) +
          Number.parseInt(this.props.currentCount)
      );
    }
    this.setState({
      row1: Number.parseInt(event.target.value),
      row2: Number.parseInt(event.target.value),
      row3: Number.parseInt(event.target.value),
      row4: Number.parseInt(event.target.value),
    });
  };

  render() {
    return (
      <tr>
        <td> {this.props.text} </td>
        <td>
          <label>
            <input
              onChange={this.handleInputChange}
              onClick={this.changeCurrent}
              type="radio"
              value="1"
              checked={this.state.current === "1"}
            />{" "}
            1
          </label>
        </td>
        <td>
          <label>
            <input
              onChange={this.handleInputChange}
              onClick={this.changeCurrent}
              type="radio"
              value="2"
              checked={this.state.current === "2"}
            />
            2
          </label>
        </td>
        <td>
          <label>
            <input
              onChange={this.handleInputChange}
              onClick={this.changeCurrent}
              type="radio"
              value="3"
              checked={this.state.current === "3"}
            />
            3
          </label>
        </td>
        <td>
          <label>
            <input
              onChange={this.handleInputChange}
              onClick={this.changeCurrent}
              type="radio"
              value="4"
              checked={this.state.current === "4"}
            />
            4
          </label>
        </td>
        <td>
          <label>
            <input
              onChange={this.handleInputChange}
              onClick={this.changeCurrent}
              type="radio"
              value="5"
              checked={this.state.current === "5"}
            />
            5
          </label>
        </td>
      </tr>
    );
  }
}

export default RadioButtons;
