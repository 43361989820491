import React from "react";
import photo from "../../../assets/social.jpg";

function Social() {
  return (
    <div>
      <h1>СОЦИАЛЕН ТИП НА ЛИЧНОСТ</h1>
      <img src={photo} alt="social" />
      <h3>
        <em>Квалитети:</em>
      </h3>
      <p>
        Социјалниот тип на личност е: корисен, кооперативен, љубезен,
        сочувствителен, одговорен, отчетен, друштвен и популарен, заинтересиран
        за проблемите на другите, сака да помага, работи со луѓе.
      </p>
      <h3>
        <em>Поволна работна средина:</em>
      </h3>
      <ul>
        <li>
          активности што вклучуваат работа со луѓе, како обука, развој,
          информации, поддршка, третман, советување, инспирација, поткрепа.
        </li>
      </ul>
      <h3>
        <em>
          Професии и занимања, соодветни за личности, со доминантен социјален
          тип:
        </em>
      </h3>
      <ul>
        <li>
          Специјалисти од професии за помош - омбудсман, психолог, психијатар,
          медијатор;
        </li>
        <li>
          Специјализиран здравствен персонал - лекар, акушер, медицинска сестра,
          рехабилитатор, санитарен техничар; специјалистичка грижа за деца со
          посебни потреби; дефектолог; терапевт за говор; физиотерапевт, фитнес
          инструктор, болничар;
        </li>
        <li>
          Специјалист за обука, развој и управување со човечки ресурси,
          наставник, предавач, обучувач, специјалист за тренинг, советник за
          кариера, педагошки специјалист;
        </li>
        <li>Услужни професии во ресторанската и хотелската индустрија;</li>
        <li>
          Службеник за информации и услуги за клиентите, специјалист за односи
          со јавноста, телефонски консултант за поддршка, телефонски оператор за
          поддршка на клиенти, социјален работник, адвокат на пациенти, свештено
          лице, итн.
        </li>
      </ul>
    </div>
  );
}

export default Social;
