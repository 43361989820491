import React from "react";
import photo from "../../../assets/artistic.jpg";

function Artistic() {
  return (
    <div>
      <h1>УМЕТНИЧКИ ТИП НА ЛИЧНОСТ </h1>
      <img src={photo} alt="artistic" />
      <p>
        <strong>Уметнички тип (творец).</strong> Меѓу професиите што ги
        преферира уметничкиот тип се: писател, менаџер за односи со
        јавноста/комуникација, актер, музичар, уметник, танчер/ка, дизајнер,
        шминкер, фотограф, модел, радио и ТВ презентер, блогер, режисер,
        пишување текст и други.
      </p>
      <h3>
        <em>Квалитети:</em>
      </h3>
      <p>
        Уметничкиот тип на личност е: надарен со богата имагинација, оригинален,
        спонтан, хаотичен, импулсивен, емотивен, интуитивен, со разновидни
        интереси, високо чувство за естетика.
      </p>
      <h3>
        <em>Поволна работна средина:</em>
      </h3>
      <ul>
        <li>
          работа што вклучува и дозволува и предизвикува креативно изразување;
        </li>
        <li>
          работа што вклучува генерирање идеи, слобода на одлуки и дејствија;
        </li>
        <li>
          способност за самоопределување на секојдневниот живот и обврски,
          флексибилност и неструктуираност.
        </li>
      </ul>
      <h3>
        <em>
          Професии и занимања, соодветни за личности, со доминантен уметнички
          тип:
        </em>
      </h3>
      <ul>
        <li>
          Актер, музичар, режисер, танчер/ка, сценограф, кореограф, акробат,
          композитор, диригент, инженер за звук, дублер, кореограф;
        </li>
        <li>
          Писател, сценарист, автор на текстови (на веб-страници и други
          медиуми), уредник, новинар, блогер/влогер, огласувач; радио или
          телевизиски презентер, говорник, модератор; специјалист за маркетинг и
          рекламирање, пишување текстови (автор на рекламни текстови);
        </li>
        <li>
          Уметник/сликар, скулптор, илустратор, занаетчија, сценограф,
          костимограф, шминкер, декоратор на храна;
        </li>
        <li>
          Моден дизајнер, златар, моден консултант, специјалист за стил и слика;
          фотограф, фоторепортер;
        </li>
        <li>
          Веб-дизајнер, графички дизајнер, аниматор, специјалист за компјутерски
          игри; специјалист за внатрешен дизајн, архитект, индустриски дизајнер;
          пејзажен архитект;
        </li>
        <li>Обучувач за личен развој; наставник по глума итн.</li>
      </ul>
    </div>
  );
}

export default Artistic;
