import React, { useEffect } from "react";
import признание from "../../../assets/acceptance.jpg";
import лидерство from "../../../assets/leadership.jpg";
import пари from "../../../assets/money.jpg";
import креативност from "../../../assets/creativity.jpg";
import тимскаРабота from "../../../assets/teamwork.jpg";
import достигнувања from "../../../assets/achievements.jpg";
import помагање from "../../../assets/help.jpg";
import независност from "../../../assets/independence.jpg";
import ризик from "../../../assets/risk.jpg";
import разновидност from "../../../assets/diversity.jpg";

function ResultsSummary(props) {
  let img = "";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="desc-wrapper">
      <h3>
        Врз основа на одговорите што ги дадовте, вашите професионални вредности
        може да се сумираат на следниов начин:
      </h3>
      <table className="results-career">
        <tbody>
          {props.resultsArray.map((el) => {
            return (
              <tr>
                <td>{el[0]}</td>
                <td>{el[1]}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
        
      {(props.resultsArray.some(e => e[1] === 4)) && (
        <h3>За вас најважно е работата да ви дава можност за:</h3>
      )} <br /> <br />
      {renderImg(4)}

      {(props.resultsArray.some(e => e[1] === 3)) && (
        <h3>Неопхoдно Ви е на работното место да имате:</h3>
      )} <br /> <br />
      {renderImg(3)}

      {(props.resultsArray.some(e => e[1] === 2)) && (
        <h3>
          Следниве професионални вредности се делумно важни, но не и одлучувачки за
          вас:
        </h3>
      )} <br /> <br />
      {renderImg(2)}

      {(props.resultsArray.some(e => e[1] === 1)) && (
        <h3>Вие не придавате големо значење на вредностите како што се:</h3>
      )} <br /> <br />
      {renderImg(1)}

      {(props.resultsArray.some(e => e[1] === 0)) && (
        <h3>За вас, следниве фактори не се важни за изборот на работа:</h3>
      )} <br /> <br />
      {renderImg(0)}

      <p>
        Овие резултати се појдовна точка за насока и размислување при изборот на
        вистинската насока во кариерата. Доколку се двоумите, ве советуваме да
        побарате помош од советник за кариера кој ќе ви помогне да донесете
        одлука.
      </p>
    </div>
  );

  function chooseImg(el) {
    if (el === "признание") img = признание;
    else if (el === "лидерство") img = лидерство;
    else if (el === "пари") img = пари;
    else if (el === "креативност") img = креативност;
    else if (el === "тимска работа") img = тимскаРабота;
    else if (el === "достигнувања") img = достигнувања;
    else if (el === "помагање") img = помагање;
    else if (el === "независност") img = независност;
    else if (el === "ризик") img = ризик;
    else if (el === "разновидност") img = разновидност;
  }

  function renderImg(numEl) {
    return (
      <div className="results-career-flex">
        {props.resultsArray.map((el) => {
          chooseImg(el[0]);

          if (el[1] === numEl) {
            return (
              <img
                className="results-img"
                src={img}
                style={{ width: '240px', marginRight: "10px"}}
                alt={el[0]}
              ></img>
            );
          }
        })}
      </div>
    );
  }
}

export default ResultsSummary;
