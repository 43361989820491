import React, { useState } from "react";
import Title from "../title";
import Description from "./description";
import TableTip from "./table";

import typesImg from "../../assets/types.jpg";

function InitState(props) {
  const [realistic, setR] = useState(0);
  const [investigative, setI] = useState(0);
  const [artistic, setA] = useState(0);
  const [social, setS] = useState(0);
  const [entrepreneur, setE] = useState(0);
  const [conventional, setC] = useState(0);
  const [required, setRequired] = useState(-1);
  const [prevName, setPrevName] = useState([]);

  const [checkRequired, setCheckRequired] = useState([]);

  let summs = {
    реалистичен: realistic,
    истражувачки: investigative,
    уметнички: artistic,
    социјален: social,
    предприемачески: entrepreneur,
    традиционален: conventional,
  };

  return (
    <section className="container">
      <Title title={"ТЕСТ ЗА ТИП НА ЛИЧНОСТ"}></Title>
      <Description></Description>
      <img src={typesImg} alt="Types" />
      <TableTip handleRadioInput={handleRadioInput}></TableTip>
      <button className="submit" onClick={genResults}>
        Испрати
      </button>
      {required === 1 && (
        <h3 style={{ color: "red" }}>*All fields are required!</h3>
      )}
    </section>
  );

  function handleRadioInput(event) {
    if (!checkRequired.includes(event.target.name)) {
      setCheckRequired((res) => {
        return [...res, event.target.name];
      });
    }

    if (
      event.target.title.toString().includes("R") &&
      event.target.value === "0" &&
      prevName.includes(event.target.name)
    ) {
      setR(realistic - 1);
    } else if (
      event.target.title.toString().includes("R") &&
      event.target.value === "1"
    ) {
      setR(Number.parseInt(event.target.value) + Number.parseInt(realistic));
    }

    if (
      event.target.title.toString().includes("I") &&
      event.target.value === "0" &&
      prevName.includes(event.target.name)
    ) {
      setI(investigative - 1);
    } else if (
      event.target.title.toString().includes("I") &&
      event.target.value === "1"
    ) {
      setI(
        Number.parseInt(event.target.value) + Number.parseInt(investigative)
      );
    }

    if (
      event.target.title.toString().includes("A") &&
      event.target.value === "0" &&
      prevName.includes(event.target.name)
    ) {
      setA(artistic - 1);
    } else if (
      event.target.title.toString().includes("A") &&
      event.target.value === "1"
    ) {
      setA(Number.parseInt(event.target.value) + Number.parseInt(artistic));
    }
    if (
      event.target.title.toString().includes("S") &&
      event.target.value === "0" &&
      prevName.includes(event.target.name)
    ) {
      setS(social - 1);
    } else if (
      event.target.title.toString().includes("S") &&
      event.target.value === "1"
    ) {
      setS(Number.parseInt(event.target.value) + Number.parseInt(social));
    }

    if (
      event.target.title.toString().includes("E") &&
      event.target.value === "0" &&
      prevName.includes(event.target.name)
    ) {
      setE(entrepreneur - 1);
    } else if (
      event.target.title.toString().includes("E") &&
      event.target.value === "1"
    ) {
      setE(Number.parseInt(event.target.value) + Number.parseInt(entrepreneur));
    }

    if (
      event.target.title.toString().includes("C") &&
      event.target.value === "0" &&
      prevName.includes(event.target.name)
    ) {
      setC(conventional - 1);
    } else if (
      event.target.title.toString().includes("C") &&
      event.target.value === "1"
    ) {
      setC(Number.parseInt(event.target.value) + Number.parseInt(conventional));
    }

    setPrevName([...prevName, event.target.name]);
  }

  function genResults() {
    if (checkRequired.length < 24) {
      setRequired(1);
    } else {
      setRequired(2);
      let sortable = [];

      for (var s in summs) {
        sortable.push([s, summs[s]]);
      }

      sortable
        .sort(function (a, b) {
          return a[1] - b[1];
        })
        .reverse();

      let results = [];
      let max = sortable[0][1];

      sortable.forEach((val) => {
        if (val[1] === max) {
          results.push(val[0]);
        }
      });
      props.setResultsArray((res) => {
        return results;
      });

      props.setFlag("results");
    }
  }
}

export default InitState;
