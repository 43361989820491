import React from "react";

function TableHead() {
  return (
    <thead>
      <tr>
        <th>Сценарио</th>
        <th>Целосно се однесува на мене</th>
        <th>Најчесто не се однесува на мене</th>
      </tr>
    </thead>
  );
}

export default TableHead;
