import React from "react";
import Title from "../../title";
import ResultsSummary from "./results-summary";

function ResultsCareer(props) {
  return (
    <section className="res-container">
      <Title
        title={"Резултати од тестот „Моите професионални вредности“"}
      ></Title>
      <ResultsSummary resultsArray={props.resultsArray}></ResultsSummary>
    </section>
  );
}

export default ResultsCareer;
