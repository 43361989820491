import React from "react";
import RadioButtons from "./radioButtons";
import TableHead from "./tableHead";

function ZboroviTable(props) {
  return (
    <div>
      <p>
        {" "}
        <em> 1. Снаоѓање со зборови </em>{" "}
      </p>
      <table className="blueTable">
        <TableHead />
        <tbody>
          <RadioButtons
            name={"row1"}
            text={
              "Учествуваш во игра во која за еден поим треба да наведеш повеќе зборови со исто значење (синоними). Ова за тебе би било:"
            }
            currentCount={props.count}
            inputChange={props.setCount}
          />
          <RadioButtons
            name={"row2"}
            text={
              "Треба да одржиш предавање на тема која за тебе е многу интересна и позната. Ова за тебе би било:"
            }
            currentCount={props.count}
            inputChange={props.setCount}
          />
          <RadioButtons
            name={"row3"}
            text={
              "После враќање од возбудливо патување треба да напишеш прилог за локалниот весник. Ова за тебе би било:"
            }
            currentCount={props.count}
            inputChange={props.setCount}
          />
          <RadioButtons
            name={"row4"}
            text={
              "На колега од друг град треба да му напишеш писмо во кое ќе ја претставиш сопствената фирма, работните позиции во истата, вашите остварувања и предизвици. Ова за тебе би било:"
            }
            currentCount={props.count}
            inputChange={props.setCount}
          />
        </tbody>
      </table>
    </div>
  );
}

export default ZboroviTable;
