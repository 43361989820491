import React, { useState } from "react";
import InitState from "../components/testZaKariera/init-state";
import ResultsCareer from "../components/testZaKariera/summary-components/results";

import "./style.css";

function TestZaKariera() {
  const [flag, setFlag] = useState("init");
  const [resultsArray, setResultsArray] = useState([]);
  if (flag === "init") {
    return (
      <InitState
        setFlag={setFlag}
        setResultsArray={setResultsArray}
      ></InitState>
    );
  } else if (flag === "results") {
    return <ResultsCareer resultsArray={resultsArray}></ResultsCareer>;
  }
}

export default TestZaKariera;
