import React from "react";
import RadioButtons from "./radioButtons";
import TableHead from "./tableHead";

function UrediTable(props) {
  return (
    <div>
      <p>
        <em> 3. Сфаќање на начинот на работа на машините и уредите </em>
      </p>
      <table className="blueTable">
        <TableHead />
        <tbody>
          <RadioButtons
            name={"row1"}
            text={
              "Се очекува од тебе да објасниш како функционира стариот часовник со видлив механизам. Ова за тебе би било:"
            }
            currentCount={props.count}
            inputChange={props.setCount}
          />
          <RadioButtons
            name={"row2"}
            text={
              "Отпаднат е дел од играчка со далечински управувач. Треба да откриеш каде тој дел стои и да го наместиш. Ова за тебе би било:"
            }
            currentCount={props.count}
            inputChange={props.setCount}
          />
          <RadioButtons
            name={"row3"}
            text={
              "Посматраш машина со зупчаници.Треба да откриеш во кој смер се вртат зупчаниците и како се врши пренос. Ова за тебе би било:"
            }
            currentCount={props.count}
            inputChange={props.setCount}
          />
          <RadioButtons
            name={"row4"}
            text={
              "Купивте нова лулашка. Треба да одредиш колкава треба да е должината на јажето за лакот  при лулањето да биде поголем. Ова за тебе би било:"
            }
            currentCount={props.count}
            inputChange={props.setCount}
          />
        </tbody>
      </table>
    </div>
  );
}

export default UrediTable;
