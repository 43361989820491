import React from "react";

function Description() {
  return (
    <div className="desc-wrapper">
      <p>
        Тестот за способности може да биде добар начин да добиете идеа за тоа во
        што сте добри или во што би можеле да бидете добри ако ја добиете
        вистинската обука. Тој е дизајниран на начин кој ви овозможува да
        направите проценка на сопственото ниво на способност при извршување
        одреден тип задачи. Фокусот е даден на проблемски ситуации за чие
        решавање се потребни одреден тип на способности. Вие проценувате дали
        тоа за вас е лесна или тешка проблемска ситуација. Резултатите од тестот
        можат да ви дадат општа идеа за тоа што би можело да биде добро за вас
        во изборот на ваша идна кариера или образование.
      </p>
      <p>
        Сепак, овој тест не може да ви каже се’. Внимателно разгледајте ги
        вашите резултати од тестот на способности, но оценете и други фактори
        како што се вашите интереси и искуства, а потоа искористете ги овие
        информации кога истражувате различни опции за кариера.
      </p>
    </div>
  );
}

export default Description;
