import React from "react";
import RadioButtons from "./radioButtons";
import TableHead from "./tableHead";

function PrstiTable(props) {
  return (
    <div>
      <p>
        {" "}
        <em> 5. Спретност со раце и прсти </em>{" "}
      </p>
      <table className="blueTable">
        <TableHead />
        <tbody>
          <RadioButtons
            name={"row1"}
            text={
              "Ти испадна ланецот од точакот. Пријател ти дава инструкции како да го наместиш. Ова за тебе би било:"
            }
            currentCount={props.count}
            inputChange={props.setCount}
          />
          <RadioButtons
            name={"row2"}
            text={
              "Имаш модел од авион кој треба да го составиш и залепиш. Ова за тебе би било:"
            }
            currentCount={props.count}
            inputChange={props.setCount}
          />
          <RadioButtons
            name={"row3"}
            text={
              "Ти испадна шраф од наочарите за сонце. Треба да го наместиш. Ова за тебе би било:"
            }
            currentCount={props.count}
            inputChange={props.setCount}
          />
          <RadioButtons
            name={"row4"}
            text={
              "За нова  година треба  да направи украси од хартија според шаблон и упатство напишано во списание. Ова за тебе би било:"
            }
            currentCount={props.count}
            inputChange={props.setCount}
          />
        </tbody>
      </table>
    </div>
  );
}

export default PrstiTable;
