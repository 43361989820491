import React, { useState } from "react";
import Title from "../title";
import Description from "./description";
import TableCareer from "./table";
import photo from "../../assets/values.jpg";

function InitState(props) {
  const [R, setR] = useState(0);
  const [L, setL] = useState(0);
  const [M, setM] = useState(0);
  const [C, setC] = useState(0);
  const [T, setT] = useState(0);
  const [A, setA] = useState(0);
  const [H, setH] = useState(0);
  const [I, setI] = useState(0);
  const [G, setG] = useState(0);
  const [V, setV] = useState(0);

  const [required, setRequired] = useState(-1);
  const [prevName, setPrevName] = useState([]);

  const [checkRequired, setCheckRequired] = useState([]);

  let summs = {
    признание: R,
    лидерство: L,
    пари: M,
    креативност: C,
    "тимска работа": T,
    достигнувања: A,
    помагање: H,
    независност: I,
    ризик: G,
    разновидност: V,
  };

  return (
    <section className="container">
      <Title title={"Тест „Моите професионални вредности“"}></Title>
      <Description></Description>
      <img src={photo} alt="values" />
      <TableCareer handleRadioInput={handleRadioInput}></TableCareer>
      <button className="submit" onClick={genResults}>
        Испрати
      </button>
      {required === 1 && (
        <h3 style={{ color: "red" }}>*All fields are required!</h3>
      )}
    </section>
  );
  function handleRadioInput(event) {
    if (!checkRequired.includes(event.target.name)) {
      setCheckRequired((res) => {
        return [...res, event.target.name];
      });
    }

    if (
      event.target.title.toString().includes("R") &&
      event.target.value === "0" &&
      prevName.includes(event.target.name)
    ) {
      setR(R - 1);
    } else if (
      event.target.title.toString().includes("R") &&
      event.target.value === "1"
    ) {
      setR(Number.parseInt(event.target.value) + Number.parseInt(R));
    }

    if (
      event.target.title.toString().includes("L") &&
      event.target.value === "0" &&
      prevName.includes(event.target.name)
    ) {
      setL(L - 1);
    } else if (
      event.target.title.toString().includes("L") &&
      event.target.value === "1"
    ) {
      setL(Number.parseInt(event.target.value) + Number.parseInt(L));
    }

    if (
      event.target.title.toString().includes("M") &&
      event.target.value === "0" &&
      prevName.includes(event.target.name)
    ) {
      setM(M - 1);
    } else if (
      event.target.title.toString().includes("M") &&
      event.target.value === "1"
    ) {
      setM(Number.parseInt(event.target.value) + Number.parseInt(M));
    }
    if (
      event.target.title.toString().includes("C") &&
      event.target.value === "0" &&
      prevName.includes(event.target.name)
    ) {
      setC(C - 1);
    } else if (
      event.target.title.toString().includes("C") &&
      event.target.value === "1"
    ) {
      setC(Number.parseInt(event.target.value) + Number.parseInt(C));
    }

    if (
      event.target.title.toString().includes("T") &&
      event.target.value === "0" &&
      prevName.includes(event.target.name)
    ) {
      setT(T - 1);
    } else if (
      event.target.title.toString().includes("T") &&
      event.target.value === "1"
    ) {
      setT(Number.parseInt(event.target.value) + Number.parseInt(T));
    }

    if (
      event.target.title.toString().includes("A") &&
      event.target.value === "0" &&
      prevName.includes(event.target.name)
    ) {
      setA(A - 1);
    } else if (
      event.target.title.toString().includes("A") &&
      event.target.value === "1"
    ) {
      setA(Number.parseInt(event.target.value) + Number.parseInt(A));
    }

    if (
      event.target.title.toString().includes("H") &&
      event.target.value === "0" &&
      prevName.includes(event.target.name)
    ) {
      setH(H - 1);
    } else if (
      event.target.title.toString().includes("H") &&
      event.target.value === "1"
    ) {
      setH(Number.parseInt(event.target.value) + Number.parseInt(H));
    }

    if (
      event.target.title.toString().includes("I") &&
      event.target.value === "0" &&
      prevName.includes(event.target.name)
    ) {
      setI(I - 1);
    } else if (
      event.target.title.toString().includes("I") &&
      event.target.value === "1"
    ) {
      setI(Number.parseInt(event.target.value) + Number.parseInt(I));
    }

    if (
      event.target.title.toString().includes("G") &&
      event.target.value === "0" &&
      prevName.includes(event.target.name)
    ) {
      setG(G - 1);
    } else if (
      event.target.title.toString().includes("G") &&
      event.target.value === "1"
    ) {
      setG(Number.parseInt(event.target.value) + Number.parseInt(G));
    }

    if (
      event.target.title.toString().includes("V") &&
      event.target.value === "0" &&
      prevName.includes(event.target.name)
    ) {
      setV(V - 1);
    } else if (
      event.target.title.toString().includes("V") &&
      event.target.value === "1"
    ) {
      setV(Number.parseInt(event.target.value) + Number.parseInt(V));
    }

    setPrevName([...prevName, event.target.name]);
  }

  function genResults() {
    if (checkRequired.length < 40) {
      setRequired(1);
    } else {
      setRequired(2);
      let sortable = [];

      for (var s in summs) {
        sortable.push([s, summs[s]]);
      }

      sortable
        .sort(function (a, b) {
          return a[1] - b[1];
        })
        .reverse();

      props.setResultsArray((res) => {
        return sortable;
      });

      props.setFlag("results");
    }
  }
}

export default InitState;
