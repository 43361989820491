import React from "react";
import RadioButtons from "./radioButtons";
import TableHead from "./tableHead";

function BroeviTable(props) {
  return (
    <div>
      <p>
        <em> 2. Снаоѓање со броеви </em>
      </p>
      <table className="blueTable">
        <TableHead />
        <tbody>
          <RadioButtons
            currentCount={props.count}
            inputChange={props.setCount}
            name={"row1"}
            text={
              "Случајно во продавница  додека  му помагаш на својот пријател, се исклучува струјата и пресметката за наплата на неколку артикли треба да ја направиш брзо затоа што  купувачите чекаат. Ова за тебе би било:"
            }
          />
          <RadioButtons
            currentCount={props.count}
            inputChange={props.setCount}
            name={"row2"}
            text={
              "Треба да искористиш формула за пресметување. Од тебе се очекува да го објасниш начинот на кој резултатот на пресметката ќе се менува зависно од броевите кои ги внесуваш во дадена формула. Ова за тебе би било:"
            }
          />
          <RadioButtons
            currentCount={props.count}
            inputChange={props.setCount}
            name={"row3"}
            text={
              "Во странство си. Ти требаат евра и треба да пресметаш колку долари треба да смениш за да добиеш 400 евра. Ова за тебе би било:"
            }
          />
          <RadioButtons
            currentCount={props.count}
            inputChange={props.setCount}
            name={"row4"}
            text={
              "Рецептата за колачот што го прави баба ти е во англиски мерки. Треба да и пресметаш  според  формула колку изнесуваат тие мерки во грами. Ова за тебе би било: "
            }
          />
        </tbody>
      </table>
    </div>
  );
}

export default BroeviTable;
